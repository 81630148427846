import * as React from "react";
import Layout from "../components/Layout/Layout";
import Grid from '@mui/material/Grid';
import CardBasic, { contextMap } from "../components/CardBasic/CardBasic";
import { getTrendingPages } from "./page-template";
import CardTitle from "../components/CardTitle/CardTitle";
import CardBanner from "../components/CardBanner/CardBanner";

const CategoryTemplate = (props: { pageContext: any; }) => {
  const { pageContext } = props
  
  var siteJson = pageContext.site;
  if (siteJson == null) {
    siteJson = new Object();
  }

  var tenantJson = pageContext.tenant;
  if (tenantJson == null) {
    tenantJson = new Object();
  }

  var categoryJson = pageContext.category;

  var pagesJson = categoryJson.pages;
  if (pagesJson == null) {
    pagesJson = [];
  }


  let categoryDescriptionHtml = "";
  if (categoryJson.category_description != null) {
    categoryDescriptionHtml = categoryJson.category_description_html;
  }

  let categoryId = categoryJson.category_id;
  let categoryTitle = categoryJson.category_title;
  let categoryImageUrl = categoryJson.category_logo_image_url;

  let siteTitle = siteJson.site_title;
  let tenantTitle = tenantJson.tenant_title;
  let tenantId = tenantJson.tenant_id;

  let tenant_logo_image_url = tenantJson.tenant_logo_image_url;


  return (

    <Layout
    dynamicMenuItems={[]}
    pageTitle={categoryTitle}
    siteHeaderLogoImageUrl={siteJson.site_header_logo_image_url}
    siteTitle={siteTitle}
    tenantId={tenantJson.tenant_id}
    > 

      <Grid container spacing={5}>  {/* MAIN GRID */}
 
        <Grid item xs={12} sm={8} maxWidth={900}>       {/* FIRST COLUMN */}
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <CardBanner title={tenantTitle} linkTo={'/tenant/' + tenantId} imageUrl={tenant_logo_image_url} cardDisplay={tenantJson.card_display} context={contextMap.TENANT_BANNER}></CardBanner>
            </Grid>

            <Grid item xs={12}>              
              <CardTitle title={categoryTitle} 
              descriptionHtml={categoryDescriptionHtml}
              linkTo={'/tenant/' + tenantId + '/category/' + categoryId} imageUrl={categoryImageUrl} cardDisplay={categoryJson.card_display} context={contextMap.CATEGORY_TITLE}></CardTitle>
            </Grid>
            <br></br>

            {
              pagesJson.map((pageJson: { page_title: string; page_id: string; page_logo_image_url: string; }) => (
                <Grid item xs={12} sm={6}>
                  <CardBasic title={pageJson.page_title} linkTo={'/tenant/' + tenantId + '/category/' + categoryId + '/page/' + pageJson.page_id} imageUrl={pageJson.page_logo_image_url} cardDisplay={pageJson.card_display} context={contextMap.PAGE_CARD}></CardBasic>
                </Grid>
              ))
            }
          </Grid>
        </Grid>                                         {/* END FIRST COLUMN */}

        <Grid item xs={12} sm={4}>                      {/* SECOND COLUMN */}
        
         { getTrendingPages(tenantJson) }

        </Grid>                                          {/* END SECOND COLUMN */}

      </Grid>   {/* END MAIN GRID */}
    </Layout >
  )
}
export default CategoryTemplate 